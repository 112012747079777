const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: []
}

const shaftMasterReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'GET_DATA_SHAFT':
            return {
                ...state,
                allData: action?.allData,
                data: action?.data,
                total: action?.totalPages,
                params: action?.params
            }
        case 'CREATE_SHAFT':
            return { ...state }
        case 'UPDATE_SHAFT':
            return { ...state }
        case 'GET_SHAFT_DATA':
            return {
                ...state,
                masterData: action.masterData,
            }

        default:
            return { ...state }
    }
}
export default shaftMasterReducer
