const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: []
  }
  
  const carDoorMasterData = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_DATA_CAR_DOOR':
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case 'CREATE_CAR_DOOR':
        return { ...state }
      case 'UPDATE_CAR_DOOR':
        return { ...state }
      case 'GET_CAR_DOOR_DATA':
       return {
          ...state,
          masterData: action.masterData,
        }

      default:
        return { ...state }
    }
  }
  
  export default carDoorMasterData
  