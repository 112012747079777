const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  passengerData: [],
  speedData: [],
  machineData: [],
  getPassengerData: [],
  motorData: [],
  masterData:[]
};

const machineFormData = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA_MACHINE_FORM":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        passengerData: [],
      };
    case "CREATE_MACHINE_FORM":
      return { ...state };
    case "UPDATE_MACHINE_FORM":
      return { ...state };
    case "GET_DATA_PASSENGER":
      return {
        ...state,
        passengerData: action.passengerData,
      };
    case "GET_DATA_SPEED":
      return {
        ...state,
        speedData: action.speedData,
      };
    case "GET_DATA_MACHINE_MASTER":
      return {
        ...state,
        machineData: action.machineData,
      };
    case "GET_DATA_MOTOR":
      return {
        ...state,
        motorData: action.motorData,
      };

    case "GET_DATA_EXCEL":
      return { ...state };
    case "IMPORT_MACHINE_FORM_EXCEL":
      return { ...state };
    case 'GET_MACHINE_FORM_DATA':
      return {
        ...state,
        masterData: action.masterData
      }
    default:
      return { ...state };
  }
};

export default machineFormData;
