// ** Checks if an object is empty (returns boolean)
import axios from 'axios'
import { firebase, auth } from "./../base";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)
import { Spinner } from 'reactstrap'
import { decryptResponseMiddleware } from "./decryptResponseMiddleware"
import { encryptRequestMiddleware } from "./encryptRequestMiddleware"
export const isObjEmpty = obj => Object.keys(obj).length === 0
import { toast, Slide } from "react-toastify";
import fileIcon from "../assets/images/icons/file-icon.svg";

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === '123123') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const handleConfirmText = (props) => {
  return MySwal.fire({
    title: 'Your session has been expired',
    text: "login again to continue accessing your account !",
    icon: 'warning',
    showCancelButton: false,
    confirmButtonText: 'Yes, logout!',
    customClass: {
      confirmButton: 'btn btn-danger',
      cancelButton: 'btn btn-outline-danger ml-1'
    },
    allowOutsideClick: false,
    buttonsStyling: false
  }).then(function (result) {
    if (result.value) {
      firebase
        .auth()
        .signOut();
      localStorage.removeItem("userData");
      window.localStorage.clear();
      window.localStorage.clear();
      window.location.href = "/"
    }
  })

}

const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})


Axios.interceptors.request.use(async (config) => {
  await new Promise((resolve) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(() => {
      unsubscribe();
      resolve();
    });
  });
  config.headers.Authorization = `Bearer ${await firebase.auth().currentUser.getIdToken(false)}`;
  if (process.env.REACT_APP_ENCCRYPTION === "false") {
    config.headers.Encryption = process.env.REACT_APP_ENCCRYPTION; // for  encripttion  and decription mathod
  } else {
    const isFormData = config.headers['Content-Type'] === 'multipart/form-data';
    if (!isFormData && (config.method === 'put' || config.method === 'post' || config.method === 'patch') && config.data) {
      const encrypted = await encryptRequestMiddleware(config.data)
      config.data = encrypted;
    }
  }
  return config;
});
Axios.interceptors.response.use(
  response => {
    if (process.env.REACT_APP_ENCCRYPTION === "false") {
      return response;
    }
    // Check content type
    const contentType = response.headers['content-type'];
    if (contentType && contentType.includes('application/json')) {
      response.data = JSON.parse(decryptResponseMiddleware(response.data));
    }

    return response;
  },
  error => {
    if (firebase.auth().currentUser === null || error.response.status === 401) {
      handleConfirmText();
    } else {
      if (process.env.REACT_APP_ENCCRYPTION === "false") {
        return error?.response;
      }
      // Check content type
      const contentType = error.response.headers['content-type'];
      if (contentType && contentType.includes('application/json')) {
        error.response.data = JSON.parse(decryptResponseMiddleware(error.response.data));
      }
      return error?.response
      // return Promise.reject(error?.response ?? error);
    }

  }
);
const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

const SuperAdminAxios = axios.create({
  baseURL: process.env.REACT_APP_SUPER_ADMIN_API_URL,
})

export { Axios, AxiosInstance, SuperAdminAxios }

export const uploadImageToS3 = async (file, folder_name = "default") => {
  try {
    // Step 1: Request the presigned URL from the backend
    const response = await Axios.post(`/api/third-party-service/generate-presigned-url`, {
      filePath: `${folder_name}/${Date.now()}_${file.name}`,
      fileType: file.type
    });

    if (response.data.status === false) {
      toast.error("Failed to upload file", { position: toast.POSITION.TOP_RIGHT });
      return ""

      // throw new Error("Failed to generate presigned URL");
    } else {
      const presignedUrl = response.data.data;

      // Step 2: Upload the file to S3 using the presigned URL
      const uploadResponse = await axios.put(presignedUrl, file, {
        headers: {
          'Content-Type': file.type
        }
      });

      // Step 3: If upload is successful, return the file URL (without query parameters)
      if (uploadResponse.status === 200) {
        const fileUrl = presignedUrl.split('?')[0]; // Get the URL without query parameters
        // console.log("File uploaded successfully! File URL:", fileUrl);
        return fileUrl; // Return the file URL
      } else {
        toast.error("Failed to upload file", { position: toast.POSITION.TOP_RIGHT });
        return ""
        // throw new Error("Failed to upload file");
      }
    }
  } catch (error) {
    toast.error("Failed to upload file", { position: toast.POSITION.TOP_RIGHT });
    return ""
    // console.error("Error uploading file or generating presigned URL:", error);
    // throw error; // Propagate the error so the calling function can handle it
  }
};

export const deleteImageFromS3 = async (filepath) => {
  return "";
  // try {
  //   // Step 1: Request the presigned URL from the backend
  //   const response = await Axios.post(`/api/third-party-service/generate-presigned-url-for-delete`, {
  //     filePath: filepath
  //       });

  //   if (response.data.status === false) {
  //     return ""

  //     // throw new Error("Failed to generate presigned URL");
  //   } else {
  //     const presignedUrl = response.data.data;

  //     // Step 2: Upload the file to S3 using the presigned URL
  //     try {
  //       const response = await axios.delete(presignedUrl);

  //       if (response.status === 204) {
  //         console.log("File deleted successfully");
  //       } else {
  //         console.error("Failed to delete file", response);
  //       }
  //     } catch (error) {
  //       console.error("Error deleting file from S3", error);
  //     }
  //   }
  // } catch (error) {
  //   // toast.error("Failed to delte file", { position: toast.POSITION.TOP_RIGHT });
  //   return ""
  //   // console.error("Error uploading file or generating presigned URL:", error);
  //   // throw error; // Propagate the error so the calling function can handle it
  // }
};

export const CustomLoader = () => {
  return (<div style={{ position: "fixed", top: "60%", left: "60%" }}>
    <Spinner color="primary" ></Spinner>
    <h5>Loading...</h5> </div>)
}


//Function for capitalize the text .
export const capitalizeText = (text) => {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};


//restrict Negative values and allow only number
export const handleKeyPress = (event) => {
  const charCode = event.which ? event.which : event.keyCode;
  // Check if the character is a digit (0-9)
  if (charCode < 48 || charCode > 57) {
    event.preventDefault();
  }
  if (event.target.value === '' && charCode === 45) {
    return;
  }
  if (charCode === 45) {
    event.preventDefault();
  }
};

export const formatAsCurrency = (amount, currencyCode) => {
  const formattedAmount = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0, // Set the minimum number of fraction digits to 0
    maximumFractionDigits: 0
  }).format(amount);

  // Remove the currency symbol (₹) from the formatted string
  const numericPart = formattedAmount.replace(/^₹/, '');

  return numericPart;
}

// Utility function to check if URL is an image
export const isImageUrl = (url) => {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];
  const extension = url?.split('.').pop().toLowerCase(); // Extract file extension from URL
  return imageExtensions.includes(extension);
};

// Helper function to convert snake_case to camelCase
export const toCamelCase = (str) => {
  // Split the string into words based on underscore
  const words = str.split('_');

  // Map over each word and capitalize the first letter of each word except the first
  const camelCasedWords = words?.map((word, index) => {
    // if (index === 0) {
    //   return word; // Keep the first word as is
    // }
    return word?.charAt(0)?.toUpperCase() + word.slice(1); // Capitalize first letter for subsequent words
  });

  // Join the words back together to form the camelCase string
  return camelCasedWords.join(' ');
};

// Helper function to check if a string is a valid date
const isDate = (value) => {
  // Regular expression to match the date pattern: YYYY-MM-DDTHH:mm:ss.sss
  const datePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}$/;

  return typeof value === 'string' && datePattern.test(value) && !isNaN(Date.parse(value));
};


// Helper function to generate metafield columns
export const generateMetafieldColumns = (data) => {
  // Collect all unique metafield keys from all rows
  const allMetafieldKeys = new Set();
  data?.forEach(row => {
    if (row?.metafields) {
      Object.keys(row?.metafields).forEach(key => allMetafieldKeys?.add(key));
    }
  });

  // Generate columns for each unique metafield key
  return [...allMetafieldKeys]?.map((key) => ({
    name: toCamelCase(key), // Convert key to readable format
    selector: (row) => row?.metafields?.[key] || '-', // Select the metafield value
    sortable: true,
    cell: (row) => {
      const value = row?.metafields?.[key];

      // Check if the value is an object and not a string
      if (typeof value === 'object' && value !== null) {
        return <span>{"-"}</span>; // Display object as string (or handle as needed)
      }

      // Check if the value is a URL and whether it's an image
      if (typeof value === 'string' && value.startsWith('http')) {
        return isImageUrl(value) ? (
          <a href={value} target="_blank" rel="noopener noreferrer">
            <img src={value} alt={key} style={{ width: '50px', height: '50px', borderRadius: "5px" }} />
          </a>
        ) : (
          <a href={value} target="_blank" rel="noopener noreferrer">
            <img src={fileIcon} alt="File" style={{ width: '50px', height: '50px' }} />
          </a>
        );
      }

      // Otherwise, display the value as plain text or '-'
      return <span>{value || '-'}</span>;
    },
  }));
};

// Helper function to generate Metafield Details for Sidebar vertical view
export const generateMetafieldDetails = (metafields) => {
  if (!metafields) return null;
  return (<>

    <h6 className="transaction-title mt-2 mb-2">
      <strong>Metafields</strong>
    </h6>
    {Object.keys(metafields)?.map((key) => {
      const value = metafields[key];
      return (
        <>
          <div key={key} className="mb-2">
            <h6 className="transaction-title" style={{ display: "inline-block" }}>
              {toCamelCase(key)}:
            </h6>{" "}
            {typeof value === 'string' && value.startsWith('http') ? (
              // Handle URLs (images or files)
              <a href={value} target="_blank" rel="noopener noreferrer">
                {isImageUrl(value) ? (
                  <img
                    src={value}
                    alt={key}
                    style={{ width: "35px", height: "35px", borderRadius: "5px" }}
                  />
                ) : (
                  <img
                    src={fileIcon}
                    alt="File"
                    style={{ width: "35px", height: "35px" }}
                  />
                )}
              </a>
            ) : isDate(value) ? (
              // Handle date formatting
              <span>{new Date(value).toLocaleDateString("en-GB")}</span>
            ) : (
              // Default case: render plain text or a dash for missing values
              <span>{value || '-'}</span>
            )}
          </div>
        </>
      );
    })}
  </>
  )
};

// Helper function to generate Metafield Details for horizontal view
export const generateMetafieldDetailsMainView = (metafields) => {

  if (!metafields) return null;

  return (
    <div className="row mt-3">
      <div className="col-md">
        <h5 className="text-primary">Metafields:</h5>
        <div className="row mb-3">
          {Object.keys(metafields)?.map((key) => {
            const value = metafields[key];
            return (
              <div key={key} className="col-md-4 mb-2">
                <div>
                  {toCamelCase(key)}:{" "}
                  <div>
                    {typeof value === "string" && value.startsWith("http") ? (
                      // Handle URLs (images or files)
                      <a href={value} target="_blank" rel="noopener noreferrer">
                        {isImageUrl(value) ? (
                          <img
                            src={value}
                            alt={key}
                            style={{
                              width: "35px",
                              height: "35px",
                              borderRadius: "5px",
                            }}
                          />
                        ) : (
                          <img
                            src={fileIcon}
                            alt="File"
                            style={{
                              width: "35px",
                              height: "35px",
                            }}
                          />
                        )}
                      </a>
                    ) : isDate(value) ? (
                      // Handle date formatting
                      <span>{new Date(value).toLocaleDateString("en-GB")}</span>
                    ) : (
                      // Default case: render plain text or a dash for missing values
                      <strong>{value || "-"}</strong>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};